import React from 'react';
import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { Hero } from '../sections/HomeScheme/Hero';
import { SchemeInfo } from '../sections/HomeScheme/SchemeInfo';
import { Benefits } from '../sections/HomeScheme/Benefits';
import { HomeCalculatorSection } from '../sections/HomeScheme/HomeCalculatorSection';
import {SEO} from "../components/Seo/Seo";

const HomeSalarySacrifice = () => {
	return (
		<MainLayout>
			<ScPage>
				<Hero />
				<SchemeInfo />
				<Benefits />
				<HomeCalculatorSection />
			</ScPage>
		</MainLayout>
	);
};

export default HomeSalarySacrifice;

export const Head = () => (
	<SEO
		title="Gogeta Home: Savings for Employees & Employers"
		description="Discover Gogeta Home, helping employees save on big home buys and employers offer a hassle-free benefit. Reduce costs, spread payments, and enjoy more."
	/>
)
