import { useMemo } from 'react';
import { IBikeCalculatorValues } from '../helpers/bike-calculations';
import { techAndHomeCalculate } from '../helpers/tech-and-home-calculations';

const useTechAndHomeCalculations = (
  voucher: number,
  salary: number,
): IBikeCalculatorValues => {
  const calculatorValues = useMemo(() => {
    return techAndHomeCalculate(voucher, salary);
  }, [voucher, salary]);

  return calculatorValues;
};

export default useTechAndHomeCalculations;
