export interface IBikeCalculatorValues {
  platformFee: number;
  totalSalSac: number;
  monthlyTakeHomePay: number;
  taxFreePrice: number;
  totalSavings: number;
  totalSavingsPercentage: number;
  netFee: number;
  salary: number;
  voucherValue: number;
}

interface IParams {
  regular_gross?: number;
  regular_gross_12?: string;
  net_regular?: number;
  net_regular_12?: number;
  total_savings?: number;
  C2W_price?: number;
  net_total_amount?: number;
  net_total_amount_old?: number;
  total_savings_percentage?: number;
  saving_C2W?: number;
  saving_C2W_percentage?: number;
  net_fee_percentage?: number;
}

function calc_taxes(salary: number) {
  let tax = 0;
  let threshold = 0;
  let incomeTaxRates = 0;
  if (salary <= 12570) {
    tax = 0;
    threshold = 0;
    incomeTaxRates = (salary - 0) / 12;
  } else if (salary >= 12571 && salary <= 50270) {
    tax = 8.0;
    threshold = 12571;
    incomeTaxRates = (salary - (salary - 12571) * 0.08) / 12;
  } else if (salary >= 50271 && salary <= 125140) {
    tax = 2.0;
    threshold = 50271;
    incomeTaxRates = (salary - (salary - 50271) * 0.02) / 12;
  } else {
    tax = 2.0;
    threshold = 125141;
    incomeTaxRates = (salary - (salary - 125141) * 0.02) / 12;
  }

  return { data: [(100 - tax) / 100, threshold], tax: tax, incomeTaxRates };
}

function calculate_bike_salary_sacrifice_in_plp(
  voucher_amount: number,
  salary: number,
  sacrifice_repayment: number,
) {
  const voucher_value = Number(voucher_amount);
  const salary_sacrifice = sacrifice_repayment;
  const value = calc_taxes(salary)?.data;
  const tax1 = value[0];
  const threshold = value[1];
  const value2 = calc_taxes(salary - voucher_value)?.data;

  const tax2 = value2[0];
  const differenceOverThreshold = threshold;

  const netcost =
    (salary - differenceOverThreshold) * tax1 +
    (voucher_value - (salary - differenceOverThreshold)) * tax2;
  const takehomepay = netcost / salary_sacrifice;
  const savings = voucher_value - netcost;
  const savingsPercent = (savings / voucher_value) * 100;
  const context = {
    per_month: Math.round(Number(takehomepay)),
    total_savings: Number(savings).toFixed(2),
    saving_percentage: Number(savingsPercent).toFixed(2) + '%',
    tax_percent: calc_taxes(salary)?.tax,
    saving_percentage_number: Number(savingsPercent).toFixed(2),
  };

  return context;
}

export const techAndHomeCalculate = (voucher: number, salary: number): IBikeCalculatorValues => {
  const voucherValue = voucher ? Number(voucher) : 0;
  const totalVal = voucherValue;
  const gogeta_Fee = 0;
  const salary_sacrifice_amount = totalVal ? Number((voucherValue + gogeta_Fee).toFixed(2)) : 0;
  const saving = calculate_bike_salary_sacrifice_in_plp(Number(voucherValue), Number(salary), 12);

  const regular_gross = Number((salary_sacrifice_amount / 12).toFixed(2));
  const regular_gross_12 = (totalVal / 12).toFixed(2);
  const saving_percentage_number = Number(saving?.saving_percentage_number);
  const net_regular = Math.ceil((regular_gross * (1 - saving_percentage_number / 100)) * 100) / 100;
  const net_regular_12 = Number(regular_gross_12) * (1 - saving_percentage_number / 100);
  const total_savings = Number(saving?.total_savings);
  const C2W_price = net_regular * 12;
  const net_total_amount = net_regular * 12;
  const net_total_amount_old = totalVal - total_savings;
  const total_savings_percentage = Number(saving?.saving_percentage);
  const saving_C2W = voucherValue - C2W_price;
  const saving_C2W_percentage = (saving_C2W / voucherValue) * 100;
  const net_fee_percentage = Number(
    ((gogeta_Fee * (100 - saving.tax_percent)) / voucherValue).toFixed(1),
  );

  const param: IParams = {
    regular_gross,
    regular_gross_12,
    net_regular,
    net_regular_12,
    total_savings: total_savings,
    C2W_price,
    net_total_amount,
    net_total_amount_old,
    total_savings_percentage,
    saving_C2W,
    saving_C2W_percentage,
    net_fee_percentage,
  };

  return {
    platformFee: gogeta_Fee,
    totalSalSac: salary_sacrifice_amount,
    monthlyTakeHomePay: Number(param?.net_regular?.toFixed(2)),
    taxFreePrice: Number(param?.C2W_price?.toFixed(2)),
    totalSavings: Math.round(Number(param?.saving_C2W?.toFixed(2))),
    totalSavingsPercentage: Math.round(Number(param?.saving_C2W_percentage)),
    netFee: Number(param.net_fee_percentage),
    salary,
    voucherValue,
  };
};

export const validateInput = (str: string) => {
  const regex = /^[0-9]+(,[0-9]+)*(\.\d{0,2})?$/;

  return regex.test(str) || str === '';
};
